import request from "../plugins/axios";

export function workEmployee(params) {
    return request({
        url: '/workEmployee/index',
        method: 'GET',
        params
    })
}
export function updateStatus(data) {
    return request({
        url: '/contactTodo/updateStatus',
        method: 'PUT',
        data
    })
}
export function getContact(params) {
    return request({
        url: '/workEmployee/getContact',
        method: 'GET',
        params
    })
}

export function getCustomerTree(params) {
    return request({
        url: '/crm/customer/tree',
        method: 'GET',
        params
    })
}

export function store(data) {
    return request({
        url: '/contactTodo/store',
        method: 'POST',
        data
    })
}
export function contactTodo(params) {
    return request({
        url: '/contactTodo/show',
        method: 'GET',
        params
    })
}
export function update(data) {
    return request({
        url: '/contactTodo/update',
        method: 'PUT',
        data
    })
}
export function destroy(data) {
    return request({
        url: '/contactTodo/destroy',
        method: 'DELETE',
        data
    })
}
export function userCenter(params) {
    return request({
        url: '/workEmployee/center',
        method: 'GET',
        params
    })
}
